<template>
    <div class="shared-cpd">
        <b-table class="entries-table" ref="entriesTable"  :busy.sync="isBusy" striped :items="entryProvider" :fields="fields" :per-page="perPage" :current-page="currentPage" show-empty>
            <template v-slot:empty>
                <h4>No pending items</h4>
            </template>
            <template v-slot:cell(actions)="data">
                <div class="actions" style="width: 280px;">
                    <b-button @click="previewSharedCPD(data.item)" variant="secondary" size="sm">Preview</b-button>
                    <b-button @click="approve(data.item)" variant="success" size="sm">Approve</b-button>
                    <b-button @click="reject(data.item)" variant="outline-danger" size="sm">Reject</b-button>
                </div>
            </template>
        </b-table>
        <b-pagination v-show="!isBusy" class="example" align="center" :hideGotoEndButtons="true" prevText="Previous" nextText="Next" size="sm" :limit="10" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" />
        <SharedCPDAdminPreviewModal ref="previewModal" />
        <SharedCPDAdminRejectModal ref="rejectModal" @item-rejected="refresh" />
    </div>
</template>

<script>
    import Providers from '../../providers';
    import SharedCPDAdminPreviewModal from "../../components/shared-cpd/SharedCPDAdminPreviewModal";
    import SharedCPDAdminRejectModal from "../../components/shared-cpd/SharedCPDAdminRejectModal";

    export default {
        name: "SharedCPD",
        components: {SharedCPDAdminRejectModal, SharedCPDAdminPreviewModal},
        data() {
            return {
                previewItem: null,
                totalRows: 0,
                isBusy: false,
                perPage: 10,
                currentPage: 1,
                fields: [
                    {
                        key: 'metadata.author',
                        label: 'Submitter',
                        formatter: function(val) {
                            return val.firstName + ' ' + val.lastName;
                        }
                    },
                    {
                        key: 'date',
                        label: 'Submitted On',
                        formatter: function(val) {
                            return new Date(val).toLocaleDateString();
                        }
                    },
                    {
                        key: 'title',
                        label: 'Title'
                    },
                    {
                        key: 'category',
                        label: 'Category'
                    },
                    {
                        key: 'description',
                        label: 'Description'
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ]
            }
        },
        methods: {
            entryProvider: function(ctx, callback) {
                this.isBusy = true;
                Providers.admin.sharedCPD.pendingApproval(ctx.perPage, ctx.currentPage).then((resp) => {
                    callback(resp.body.items);
                    this.totalRows = resp.body.total;
                    this.isBusy = false;
                }).catch((resp) => {
                    this.$httpError('Failed to load entries', resp);
                }).finally(() => {
                    this.isBusy = false;
                })
                return null
            },
            refresh() {
                this.$refs.entriesTable.refresh();
            },
            previewSharedCPD(sharedCpd) {
                this.$refs.previewModal.show(sharedCpd);
            },
            approve(item) {
                Providers.admin.sharedCPD.approve(item.metadata.id).then(() => {
                    this.$success('Approved', 'The item has been successfully approved');
                    this.refresh();
                }).catch((resp) => {
                    this.$httpError('Failed to approve item', resp);
                });
            },
            reject(item) {
                this.$refs.rejectModal.show(item);
            }
        },

    }
</script>

<style scoped>
.actions > * {
    margin-right: 4px;
}
</style>
<template>
    <div class="shared-cpd-admin-reject">
        <b-modal class="cpd-reject-modal" title="Reject Shared CPD" ref="modal" size="lg" okTitle="Reject" okVariant="danger" @ok="deleteItem" >

            <SharedCPDListItem v-if="sharedCpd" :title="sharedCpd.title"
                               :description="sharedCpd.description"
                               :avatar-url="sharedCpd.avatarUrl"
                               :nickname="sharedCpd.nickname"
                               :category="sharedCpd.category"
                               :date="sharedCpd.date"/>

            <b-form-group class="mt-3" label="Message to submitter:">

                <b-textarea v-model="reason" :rows="5"></b-textarea>
            </b-form-group>

            <small v-if="sharedCpd"><b>Warning:</b> An email notification will be sent to the submitter of the above Shared CPD ({{ sharedCpd.metadata.author.email }}), including the message above.</small>

        </b-modal>
    </div>
</template>

<script>
    import SharedCPDListItem from "./SharedCPDListItem";
    import Providers from '../../providers';

    export default {
        name: "SharedCPDAdminRejectModal",
        components: {SharedCPDListItem},
        data() {
            return {
                sharedCpd: null,
                reason: '',
            }
        },
        methods: {
            show(sharedCpd) {
                this.sharedCpd = sharedCpd;
                this.$refs.modal.show();
            },
            deleteItem($e) {
                $e.preventDefault();
                Providers.admin.sharedCPD.reject(this.sharedCpd.metadata.id, this.reason).then(() => {
                    this.$success('Shared CPD Rejected', 'The item has been returned to the submitter');
                    this.$emit('item-rejected');
                    this.$refs.modal.hide();
                }).catch((resp) => {
                    this.$httpError('Failed to return entry', resp);
                });
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <div class="shared-cpd-admin-preview">
        <b-modal class="cpd-modal" title="Preview Shared CPD" ref="modal" size="lg" okTitle="Close" :okOnly="true" >

            <SharedCPDListItem v-if="sharedCpd" :title="sharedCpd.title"
                               :description="sharedCpd.description"
                               :avatar-url="sharedCpd.avatarUrl"
                               :nickname="sharedCpd.nickname"
                               :category="sharedCpd.category"
                               :date="date"/>

        </b-modal>
    </div>
</template>

<script>
    import SharedCPDListItem from "./SharedCPDListItem";

    export default {
        name: "SharedCPDAdminPreviewModal",
        data() {
            return {
                sharedCpd: null,
            }
        },
        methods: {
            show(sharedCpd) {
                this.sharedCpd = sharedCpd;
                this.$refs.modal.show();
            }
        },
        computed: {
            date() {
                return this.moment(this.sharedCpd.date).format('DD/MM/YYYY');
            }
        },
        components: {SharedCPDListItem}
    }
</script>

<style scoped>

</style>